<template>
  <div id="app">
    <div id="nav">
      <div class="nav-show" >
          <div class="nav-logo" >
            <img class="logo" src="images/logo.png"></img>
          </div>
        <navIcon @OPEN_NAV="showNavList" @CLOSE_NAV='closeNavList' ></navIcon>
      </div>
      
      <navBar :navshow='navShow' @about="showAbout" @product="showProduct" @contact="showContact"></navBar>
      
    </div>
    <router-view class="page" />
    <div id="icp">
      <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备19079927号-1</a> 
    </div>
  </div>
</template>

<script>
  import navIcon from './components/nav-icon';
  import navBar from './components/nav-bar';


  export default{
      name:'app',
      components:{
        navIcon,navBar
      },
      data(){
          return{
              navShow:false,
          }
      },
      methods:{
        showNavList(){
            this.navShow=true;
        },
        closeNavList(){
            this.navShow=false;
        },
        showAbout(){
          // console.log('about')
          this.$root.eventHub.$emit('SHOW_ABOUT','all');
        },
        showProduct(){
          // console.log('product')
          this.$root.eventHub.$emit('SHOW_PRODUCT','all');

        },
        showContact(){
          // console.log('contact')
          this.$root.eventHub.$emit('SHOW_CONTACT','all');

        }
      }
  }
</script>

<style lang="scss" scoped>


#app {
  font-family: 'Avenir', Helvetica, Arial,"\9ED1\4F53";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  overflow: hidden;
}
#nav {
  position: fixed;
  top: 0;
  width: 100%;
  background: #3e3a39;
  z-index: 2
}
  
.nav-show{
  max-width: 1600px;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 55px;
  background: #3e3a39;
  .nav-logo{
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 81px 40px;
    }
    img{
        width: 81px;
        height: 41px;
        margin-top: 8px;
    }
}
#icp{
    position: fixed; z-index: 1000; width: 100%; background: #3e3a39; bottom :0;

}
#icp a:visited,a:hover,a:link{
  color:#fff
}

@media (max-width: 850px){
      .page{
        width: 100%;
        height: 100%;
        // margin: 0 auto;
        padding: 0;
        margin-top: 55px;
        overflow: hidden;

      }
       #icp{
        height: auto;
        font-size: 0.16rem;
        line-height: auto;
      }
}
@media (min-width: 850px){
    .page{
      width: 100%;
      height: calc(100% - 55px);
      // margin: 0 auto;
      padding: 0;
      // margin-top: 55px;
      overflow: hidden;
      position: absolute;
      top:55px;
    }
    #icp{
        height: 20px;
        font-size:12px;
        line-height: 20px;
   }
}

</style>
