<template>
  <div class="intro">
      <div class="intro-box">
        <div class="intro-tt"></div>
        <div class="intro-list">
          <div class="lists">Neku秉承【实用の美学】理念，提供简约实用、用料安全、品控严谨的宠物用品和食品。Neku将丰富的实验研究成果，结合用户的宝贵体验，注入到产品的设计研发与升级迭代中。Neku,与您共享有趣而美好的养宠生活!</div>

<div class="lists">Neku「実用の美学」の理念に基づき、使用簡単、厳密な品質管理、安全安心な原料を使用したペット用品を提供いたします。実験研究結果、ユーザーフィードバックを製品の研究開発とモデルチェンジに入れ込みます。ネクは素晴らしい、面白いペットライフを飼い主と共に築きます！</div>


        </div>
      </div>

  </div>
</template>

<script>
export default {
  name: 'intro',
  data() {
    return {
      
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  components: {
  
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
  
@media (min-width: 850px) {
      .intro{
      position: relative;
      background: #f3f3f4;
      height: 100%;
      width: 100%;
        .intro-box{
          width:100%;
          max-width: 1400px;
          min-width: 1024px;
          height: 513px;
          margin: auto;
          position: absolute;
          top: 50%; left: 50%;
          transform: translate(-50%, -50%);
          .intro-tt{
             width:100%;
             height: 171px;
             display: block;
             float: left;
             background: url('../assets/part2title.png') no-repeat center top;
          }
          .intro-list{
             width: 70%;
             height: 100%;
             display: block;

             .lists{
              width: 100%;
              padding-bottom: 50px;
              font-size: 16px;
              margin-bottom:50px;
              margin-left:20px;
              margin-right:20px;
              border-bottom: 1px solid #ccc;
              white-space:normal;
              line-height: 30px;
              text-align: left;
              float: left;

             }
              .lists:last-child{
                border-bottom: none;
               }

              
          }
        }
      }
  }
@media (max-width: 850px) {
    .intro{
      position: relative;
      background: #f3f3f4;
      height: auto;
      width: 100%;
        .intro-box{
          width: 100%;
          height: 100%;
          margin: auto;
          padding-bottom: 0.4rem;
          .intro-tt{
             width:100%;
             height: 2.96rem;
             margin-bottom:2rem;
             background: url('../assets/part2title-m.png') no-repeat center bottom;
             background-size:4.346667rem 0.773333rem;
          }
          .intro-list{
             width: 80%;
             height: 100%;

             .lists{
              font-size: .32rem;
              white-space:normal;
              line-height: 0.5rem;
              text-align: left;
              display: inline-block;
              padding-top: 0.6666666667rem;
              padding-bottom: 0.6666666667rem;
              border-bottom: 1px solid #ccc;
            }
           
            .lists:last-child{
                border-bottom: none;
              margin-bottom: 2rem;
                
            }
            .lists:nth-child(1){
                padding-top: 0;
            }

      }
       
    }
  }
}
</style>
