<template>
  <div class="catsnacks">
      <div class="catsnacks-box">

        <div class="product-box">
            <div class="product-bottom">
              <img src="images/p7pro.png" alt="">
            </div>
           
        </div>
        <mbtn class='mbtnpos' :title="mtitle" v-if='!showIntroB' @click.native="IntroShowCon()"></mbtn>
        <transition name="slide-fade" >
          <div class="page-title" v-if='!showBigPic'>
              <img src="images/p7title.png" alt="">
          </div>
      </transition>
        <div class="spec-img" v-if='!showIntroB'>
            <img src="images/p7spec.png" alt="">
        </div>
        <transition name="slide-fade">
        <div class="select-img" v-if='showBigPic'>
             <div v-for='(item,index) in plistBigdata' :key="index" v-show='index===selectID' class="selectbigimg">
                <img :src="item" alt="">
              </div>
          </div>
        </transition>
        <transition name="slide-fade">
        <div class="introImgbox" v-if='!showIntroB'>
            <div class="intro-select-box">
                <div v-for='(item,index) in plistdata'  :key="index" class="selectimg" id='index' @mouseover='smImgMoveOver(index)' @mouseleave='smImgMoveOut'>
                  <img :src="item" alt=""  >
                </div>
            </div>
        </div>
      </transition>
        <transition name="slide-fade">
          <div class="introbox" v-if='showIntroB'>
            <div class="intro-img" v-if='!showBigPic' :style="{ backgroundImage: 'url(' + introimg + ') ' }">
                <cbtn class="closebtn" @click.native="IntroShowCon()"></cbtn>
            </div>
          </div>
      </transition>

      </div>
      <div class="catsnacks-mobile">

        <div class="product-box">

          <div class="page-title">
            <img src="images/p7title-m.png" alt="">
        </div>
        <div class="spec-img" >
            <img src="images/p7spec.png" alt="">
        </div>

        <div class="mbtn-box">
            <mbtn class='mbtnpos' :title="mtitle" @click.native="IntroShowConMobile()"></mbtn>
        </div>
          <div class="product-bottom">
            <!-- <img src="images/p4pro2.png" alt=""> -->
            <img src="images/p7pro-m.png" alt="">
          </div>
         
        </div>

        

      </div>  
  </div>
</template>

<script>
  // import config from '../http/config';
  import mbtn from './morebtn'
  import cbtn from './cbtn'

export default {
  name: 'catsnacks',
  data() {
    return {
        mtitle:'详情',
        plistdata:[
          'images/p7smpicA.png',
          'images/p7smpicB.png',
          'images/p7smpicC.png',
          'images/p7smpicD.png',
        ],
        plistBigdata:[
          'images/p7biga.png',
          'images/p7bigb.png',
          'images/p7bigc.png',
          'images/p7bigd.png',
        ],
        introimg:'images/p7intro.png',
        showIntroB:false,
        showBigPic:false,
        selectID:null,
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
     IntroShowCon(){
          this.showIntroB=!this.showIntroB;      
      },
      IntroShowConMobile(){
        this.$emit('SHOW_INTRO_MOBILE',4)

      },
      smImgMoveOver(id){
        this.showBigPic=true;
        this.selectID=id;
      },
      smImgMoveOut(){
        this.showBigPic=false;
        this.selectID=null;
      }
  },
  components: {
    mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media(min-width: 850px){
  
  .catsnacks{
      width: 100%;
      height: 100%;
      background: #efefef;
      color:#3e3a39;
      .catsnacks-box{
           display: block;
         @media (max-width: 1440px) {
           display: block;
           width: 90%;
           height: 90%;
          
        }
        @media (min-width: 1440px) {
          display: block;
           width: 100%;
           height: 100%;
           min-width: 1024px;
           max-width: 1400px;
           min-height: 700px;
           max-height: 800px;
           
        }
           left:50%;
           top:50%;
           position: absolute;
           transform: translate(-50%,-50%);
      }
      .catsnacks-mobile{
        display: none;
      }
      .mbtnpos{
        bottom: 24px;
        left: 0;
      }
      .page-title{
        position: absolute;
        left:0;top:0;
        width: 61px;
        height: 236px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .spec-img{
        position: absolute;
        bottom: 130px;
        width: 489px;
        height:146px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .select-img{
           @media (min-width: 1440px) {
              width: 465px;
           }
           @media (max-width: 1440px) {
              width: 465*0.8px;
           }  
          // height: 100%;
          position: absolute;
          top:0;left: -6px;
          background-position: left center;
          background-repeat: no-repeat;
          img{
            width: 100%;
          }
         
        }
      .product-box{
        position: absolute;
        min-width: 80%;
        height: 810px;
        left:429px;
        .product-bottom{
          position: absolute;
          @media (min-width: 1440px) {
              width:100%;
          }
          @media (max-width: 1440px) {
              width: 85%;
          }
          top:56%;
          left: 50%;
          transform: translate(-50%,-50%);
          img{
            width: 100%;
          }
        }
      }
      .introImgbox{
        position: absolute;
        bottom: 0;left: 108px;
        // width: 244px;
        height: 105px;
      .intro-select-box{
          width: 100%;
          height: 100%;
          .selectimg{
              width: 105px;
              height: 105px;
              float: left;
              margin:8px;
              cursor: pointer;
          } 
        }
        
      }
      .introbox{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 498px;
        height: 339px;
        .intro-img{
          width: 100%;
          height: 100%;
          position: absolute;
          top:0;left: 0;
          background-position: left center;
          background-repeat: no-repeat;
          background-size: contain;
        }

      }

      .closebtn{
        left: 498px;
      }
  }
}
@media(max-width: 850px){
 .catsnacks{
      width: 100%;
      height: 14.8666666667rem;
      background: #e5e5e5;
      color:#3e3a39;
      .catsnacks-box{
        display: none;
      }
      .catsnacks-mobile{
        display: block;
      }
      .mbtn-box{
        margin-top: 0.9066666667rem;
        height: 0.5133333333rem;
        position: relative;
         .mbtnpos{
            // top: 50%;
            left: 4.0866666667rem;
          }
      }
      .page-title{
        width: 4.48rem;
        padding-top: 1.28rem;
        padding-left: 1rem;
        padding-right: 1rem;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .spec-img{
        width: 7.6666666667rem;
        height: 2.0533333333rem;
        padding-top: 1.28rem;
        img{
          width:100%;
          height: 100%;
        }
      }
      .product-bottom{

        position: relative;
        width:9.6133333333rem;
        height: 5.8rem;
        margin-top: 0.7066666667rem;
         img{
          width: 100%; height: 100%;
        }
      }
        
    }
}
</style>
