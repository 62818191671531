// cnpm install --save amfe-flexible axios gsap vue-awesome-swiper@3.1.3 vue-jsonp vue-meta-info vue-router vue-scrollto vuex weixin-js-sdk sass node-sass sass-loader
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'amfe-flexible';
import gsap from "gsap";
import VueScrollTo from 'vue-scrollto';
import MetaInfo from 'vue-meta-info'
import 'swiper/css/swiper.css'
import './style/main.scss';
import wx from 'weixin-js-sdk';
import getWxconfig from './http/getWxconfig';

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        // window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

var orginShareObj={
    title: '呢酷宠物', // 分享标题
    desc: 'Nekupet', // 分享描述
    link: 'http://nekupet.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: 'http://nekupet.com/images/wx.jpg', // 分享图标
    type: '', // 分享类型,music、video或link，不填默认为link
    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    success: function () {
    // 用户点击了分享后执行的回调函数
    }
  }
// import VueJsonp from 'vue-jsonp'
// If you want to setup the global timeout, just:

Vue.use(VueAwesomeSwiper, /* { default global options } */)
Vue.use(gsap)
// Vue.use(VueJsonp, 5000)
Vue.use(VueScrollTo)
Vue.use(MetaInfo)

Vue.config.productionTip = false

gsap.install(window); // This will make everythng global.

if(isWeixinBrowser()){
  getWxconfig.getWx(function(data){
     console.log(data);
     wx.config(data);
     wx.ready(function(){
       wx.onMenuShareAppMessage(orginShareObj);
       wx.onMenuShareTimeline(orginShareObj);
       wx.onMenuShareWeibo(orginShareObj);
    })
  });
}
function isWeixinBrowser(){ return /micromessenger/i.test(navigator.userAgent); }


new Vue({
  router,
  store,
  render: h => h(App),
  data(){
    return{
      eventHub:new Vue()
    }
  }
}).$mount('#app')
