<template>
  <div class="deodorizing">
      <div class="deodorizing-box">
        <div class="product-box">
            <div class="product-left">
              <img src="images/p6pro2.png" alt="">

            </div>
            <div class="product-right">
              <img src="images/p6pro1.png" alt="">

            </div>
        </div>
        <div class="page-title">
            <img src="images/p6title.png" alt="">
        </div>
        <div class="spec-img" >
            <img src="images/p6info1.png" alt="">
            <img src="images/p6info2.png" alt="">
        </div>
      </div>

    <div class="deodorizing-mobile">
      
      <div class="page-title">
            <img src="images/p6title-m.png" alt="">
        </div>


        <div class="spec-img" >
              <img src="images/p6info1.png" alt="">
            <img src="images/p6info2.png" alt="">
        </div>
        
        <div class="product-box">
            <div class="product-left">
              <img src="images/p6pro1.png" alt="">
            </div>
            <div class="product-right">
              <img src="images/p6pro2-right.png" alt="">
            </div>
        </div>

    </div>

  </div>
</template>

<script>
  // import config from '../http/config';
  // import mbtn from './morebtn'
  // import cbtn from './cbtn'

export default {
  name: 'deodorizing',
  data() {
    return {

    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
    
  },
  components: {
    // mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 850px) {
  .deodorizing{
      width: 100%;
      height: 100%;
      background: #f3f3f4;
      color:#3e3a39;
      .deodorizing-box{
           display: block;
          @media (max-width: 1440px) {
            width: 90%;
           height: 90%;
           
        }
        @media (min-width: 1440px) {
           width: 100%;
           height: 100%;
           min-width: 1024px;
           max-width: 1400px;
           min-height: 500px;
           max-height: 800px;
        }
           left:50%;
           top:50%;
           position: absolute;
           transform: translate(-50%,-50%);
      }
      .deodorizing-mobile{
        display: none;
        width: 100%;
       }
      .page-title{
        position: absolute;
        right:0;top:0;
        width: 101px;
        height: 328px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .spec-img{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 155px;
        img{
            float: right;
            margin-left: 67px;
        }
      }
      .product-box{
        position: absolute;
        left:0;
        bottom:0;
        width: 910px;
        height: 647px;
        .product-left{
          position: absolute;
          width: 497px; height: 619px;
          bottom: 15px;
          @media (max-width: 1440px) {
              width: 497*0.9px; height: 619*0.9px;
              bottom: 15px;
            img{
                width: 100%; height: 100%;
              }
          }
        }
        .product-right{
          position: absolute;
          width: 342px; height: 316px;
          @media (max-width: 1440px) {
              width: 342*0.9px; height: 316*0.9px;
              right:120px;
              img{
                width: 100%; height: 100%;
              }
            
          }
          right:0;
          bottom: 0;
          
        }
      }
     
  }
}
  
@media(max-width: 850px){
    .deodorizing{
       width: 100%;
      height: auto;
      background: #f3f3f4;
      color:#3e3a39;
       .deodorizing-box{
        display: none;
       }
        .deodorizing-mobile{
        display: block;
        width: 100%;
       }

       .page-title{
            padding-top: 1.2rem;
            width: 100%;
            height:1.6666666667rem;
            img{
              height:100%
            }
       }

       .spec-img{
        height: 2.1866666667rem;
        width: 100%;
        margin-top: 1rem;
          img{
            margin-left: 0.8rem;
            margin-right: 0.8rem;
            height: 2.1866666667rem;
          }
       }
       .product-box{
           width: 100%;
           margin-top: 1rem;
           position: relative;
           height: 7.6066666667rem;
          .product-left{
              position: absolute;
              width: 3.4133333333rem; height: 3.1466666667rem;
              left:0.6533333333rem; bottom: 0.6rem;
              img{
                width: 100%;height: 100%;
              }
          }
          .product-right{
              position: absolute;
              width: 5.08rem; height: 6.32rem;
              bottom: 1rem; right: 0.4rem;
              img{
                width: 100%;height: 100%;
              }
          }
       }
    }
}

</style>
