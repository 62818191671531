<template>
  <div class="catlitter">
      <div class="catlitter-box">
        <mbtn class='morebtn' :title="mtitle" v-if='!showIntroB' @click.native="IntroShowCon()" ></mbtn>
       
        <div class="page-title">
            <img  src="images/p3title.png" alt="">
        </div>
         <div class="product">
            <img src="images/p3pro.png" alt="">
        </div>


        <div class="p-lists">
            <div v-for="(item,index) in plistdata" :key='index' class="pic-list">
                <!-- <img :src="item" alt=""> -->
                <div class="imgsc" :style="{ backgroundImage: 'url(' + item + ') ' }"></div>
            </div>
        </div>
        <transition name="slide-fade">
        <div class="p-intro" v-if='showIntroB'>
            <div class="imgsc" :style="{ backgroundImage: 'url(' + introimg + ') ' }"></div>
            <cbtn class="closebtn" @click.native="IntroShowCon()"></cbtn>
        </div>
      </transition>
      </div>

    <div class="catlitter-moblie">
       
        <div class="page-title">
            <img  src="images/p3title-m.png" alt="">
        </div>

        <div class="btnbox">
          <!-- <mbtn :title="mtitle" class='morebn' @click.native="IntroShowConMobile()" ></mbtn> -->
        </div>

         <div class="product">
            <img src="images/p3pro-m.png" alt="">
        </div>
        
        
        <div class="p-lists">
            <div v-for="(item,index) in plistdata" :key='index' class="pic-list">
                <!-- <img :src="item" alt=""> -->
                <div class="imgsc" :style="{ backgroundImage: 'url(' + item + ') ' }"></div>
            </div>
        </div>

      </div>
  </div>
</template>

<script>
  // import config from '../http/config';
  import mbtn from './morebtn'
  import cbtn from './cbtn'
export default {
  name: 'catlitter',
  data() {
    return {
        mtitle:'详情',
        plistdata:[
          'images/p3p0.png',
          'images/p3p1.png',
          'images/p3p2.png',
          'images/p3p3.png',
          'images/p3p4.png',
          'images/p3p5.png',
        ],
        introimg:'images/p3intro.png',
        showIntroB:false
    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
     IntroShowCon(){
          this.showIntroB=!this.showIntroB;      
    },
    IntroShowConMobile(){
        this.$emit('SHOW_INTRO_MOBILE',1)
    }
  },
  components: {
    mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media(min-width: 850px){
 .catlitter{
      width: 100%;
      height: 100%;
      background: #fcfcfc;
      color:#3e3a39;
      position: relative;
      .catlitter-moblie{
        display: none;
      }
      .catlitter-box{
           display: block;
           @media (max-width: 1440px) {
            width: 90%;
            height: 90%;
          }
          @media (min-width: 1440px) {
             display: block;
             width: 100%;
             height: 100%;
             min-width: 1024px;
             max-width: 1400px;
             min-height: 500px;
             max-height: 800px;
          }
           left:50%;
           top:50%;
           position: absolute;
           transform: translate(-50%,-50%);
      }
      .morebtn{
        bottom: 24px; right: 0px;
      }
      .product{
        width: 482px;
        @media (max-width: 1440px) {
            width: 0.9*482px;
            top:50px;
            right: 20px;
           img{
              width: 100%;
            }
        }
        position: absolute;
        right:0px;
        top: 0px;
      }
      .page-title{
          width: 212px;
          height: 344px;
          position: absolute;
          left:0px;
          top: 0px;
      }

      .p-lists{
        width:50%;
        height: 45%;
        position: absolute;
        left: 0px;
        bottom: 0px;
        text-align: right;
        .pic-list{
          width: 200px;
          height: 180px;
          float: left;
          vertical-align: bottom;
          @media (max-width: 1440px) {
            width: 200*0.9px; height: 180*0.9px;
          }
          @media (min-width: 1440px) {
            width: 200px; height: 180px;
          }
          .imgsc{
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
      .p-intro{
        width: 421px; height: 98px; position: absolute;
        right: 0px;bottom: 0px;
        .imgsc{
          width: 100%;
          height: 100%;
          opacity:1;
          background-repeat: no-repeat;
          background-position: center center;
        }
        .closebtn{
          right: 0px;
          top:0px;
        }
      }
  }
}
  
@media(max-width: 850px){
 .catlitter{
      width: 100%;
      height: auto;
      background: #fcfcfc;
      color:#3e3a39;
      position: relative;
      .catlitter-box{
          display: none;
      }
      .catlitter-moblie{
        width: 100%;
        height: 100%;
      }
      .product{
        width: 8.12rem;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .page-title{
        width: 7.3733333333rem;
        padding-top: 1.08rem;
        img{
          width: 100%;
          height: 100%;
        }
        
      }
      .btnbox{
        width: 100%;
        height: 1.0rem;
        position: relative;
        .morebn{
          left: 50%;
          top: 48%;
          transform: translate(-50%,-50%);

        }
      }

      .p-lists{
          width: 100%;
          padding-bottom:0.5rem;
        .pic-list{
            width: 3.22rem;
            height:2.4866666667rem;  
            background: #f2f2f2;
            border-radius: 18px;
            padding:5%;
            margin-left: 2%;
            margin-right: 2%;
            margin-top: 1%;
            margin-bottom: 1%;
            display: inline-block;
          .imgsc{
              width:90%;
              height: 90%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
          }
        }
      }
      .p-intro{
       
        .imgsc{
         
        }
        .closebtn{
          
        }
      }
  }
}

</style>
