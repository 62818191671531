<template>
  <div class="pagefooter">
      <div class="pagefooter-box">
          <div class="company-flyaway">
            <img src="images/footerintro.png" alt="">
          </div>
          <div class="urcode">
            <img src="images/urcode.png" alt="">
          </div>
          <div class="info-lists">
            <div class="info-list">代理合作<br>
加盟代理
</div>
            <div class="info-list">
             
              E-mail<br>
              <span>sales@nekupet.com</span>
            </div>
            <div class="info-list">Wechat<br>
            <span>nekupet</span></div>
          </div>

           <div class="info-lists">
            <div class="info-list-max">地址/住所<br>
            <span>深圳市南山区南山街道创业路18号怡海广场西17C</span><br>
<span>深圳市南山区南山街道創業路18号怡海広場⻄西17C</span> <br>
</div>

          </div>

           <div class="info-lists">
            <div class="info-list">简历请发送⾄<br>
履歴書送り先<br>
</div><div class="info-list">
E-mail<br>
<a href="mailto:hr@nekupet.com"><span>hr@nekupet.com</span></a></div>
            
          </div>
        <div class="copyrights">
            Copyright © 2019Neku Co.,Ltd. 深圳市呢酷商业有限公司
            <a href="#"><img src="images/ins-icon.png" alt=""></a>
            <a href="#"><img src="images/fa-icon.png" alt=""></a>
            <a href="#"><img src="images/fins-icon.png" alt=""></a>
          </div>
      </div>

      <!-- mobile -->
      <div class="pagefooter-mobile">
          <div class="company-flyaway">
            <img src="images/footerintro.png" alt="">
          </div>
          <div class="urcode">
            <img src="images/urcode.png" alt="">
          </div>

          <div class="info-lists ">
            <div class="info-list">
              代理合作<br>
              加盟代理
            </div>
            <div class="info-list list-line bottom-rem">
              E-mail<br>
              <span>sales@nekupet.com</span>
            </div>
            <div class="info-list list-line bottom-rem">Wechat<br>
            <span>nekupet</span></div>
          </div>



           <div class="info-lists">
              <div class="info-list ">地址/住所<br>
              <span>深圳市南山区南山街道创业路18号怡海广场西17C</span><br>
              <span>深圳市南山区南山街道創業路18号怡海広場⻄西17 C</span> 
              </div>
          </div>

           <div class="info-lists">
            <div class="info-list bottom-rem">
              简历请发送⾄<br>
              履歴書送り先<br>
              <a href="mailto:hr@nekupet.com"><span>hr@nekupet.com</span></a></div>
          </div>
        <div class="copyrights">
           Copyright © 2019Neku Co.,Ltd.<br> 深圳市呢酷商业有限公司
            </div>
      </div>

  </div>
</template>

<script>
  // import config from '../http/config';
  // import mbtn from './morebtn'
  // import cbtn from './cbtn'

export default {
  name: 'pagefooter',
  data() {
    return {

    }
  },
  created(){
  },
  computed: {
   
  },
  mounted(){

  },
  methods:{
    
  },
  components: {
    // mbtn,cbtn
  },
  watch:{

  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 850px) {
  .pagefooter{
      width: 100%;
      height: 100%;
      background: #3e3a39;
      color:#3e3a39;
      .pagefooter-box{
         @media (max-width: 1440px) {
                width: 90%;
                height: 90%;
            }
           width: 100%;
           height: 100%;
           min-width: 1024px;
           max-width: 1400px;
           min-height: 500px;
           max-height: 600px;
           left:50%;
           top:50%;
           position: absolute;
           transform: translate(-50%,-50%);
      }
      .pagefooter-mobile{
          display: none;
      }
      .company-flyaway{
        position: absolute;
        width: 101px; height: 193px; right: 0; top: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .urcode{
        position: absolute;
        width: 140px; height: 161px;
        right: 0;bottom:170px;

        img{
          width: 100%;
          height: 100%;
        }
      }
      .copyrights{
          width: 100%;
          height: 20px;
          margin-top: 40px;
          padding-top: 20px;
          padding-left:10px;
          padding-right: 10px;
          border-top:1px solid #9f9d9c;
          display: inline-block;
          color:#fff;
          opacity: 0.5;
          text-align: left;
          font-size: 15px;
          line-height: 20px;
          img{
            float: right;
            vertical-align: middle;
            margin-right: 20px;
            width: 20px;
            height:20px;
          }
        }
      .info-lists{
        width: 100%;
        height: 30%;
        font-size: 20px;
        white-space:nowrap;
        .info-list{
          display: inline-block;
          width: 13%;
          line-height: 26px;
          padding: 10px;
          margin: 10px;
          float: left;
          text-align: left;
          color:#fff;
          font-weight: bold;
          
          span{
              font-weight: normal;
              font-size: 18px;
              color:#fff;

          }
        }
        .info-list-max{
          display: inline-block;
          width: 100%;
          line-height: 26px;
          padding: 10px;
          margin: 10px;
          float: left;
          text-align: left;
          color:#fff;
          font-weight: bold;
          
          span{
              font-weight: normal;
              font-size: 18px;
              color:#fff;

          }
        }
        
      }
    
  }
}

@media (max-width: 850px) {
  .pagefooter{
      width: 100%;
      height: auto;
      background: #3e3a39;
      color:#3e3a39;
      .pagefooter-box{
          display: none;
      }
      .pagefooter-mobile{
         display: block;
         width: 100%;
         position: relative;
      }
      .company-flyaway{
        display: none;
      }
      .urcode{
        position: absolute;
        width: 2.1733333333rem; height: 2.4933333333rem;
        right: 10%;bottom: 0.68rem;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .copyrights{
          width: 56%;
          height: 1.5rem;
          border-top:1px solid #9f9d9c;
          display: inline-block;
          color:#b0afaf;
          text-align: left;
          line-height: 0.4rem;
          padding-top: 0.6rem;
          margin-right: 24%;
          font-size: 12px;
        }
      .info-lists{
        width: 80%;
        font-size: 0.25rem;
        white-space:normal;
        height: auto;
        padding-top: 0.6rem;
        .info-list{
          float: left;
          text-align: left;
          color:#fff;
          padding-top: 0.6rem;
          line-height: 0.4rem;
          span{
              font-weight: normal;
              font-size: .32rem;
              color:#fff;
          }
        }
      }
      .list-line{
        border-bottom: 1px solid #9f9d9c;
      }
      
      .bottom-rem{
        padding-bottom:0.6rem;
      }

      .info-lists .info-list:nth-child(1){
          width: 100%;
      }
      .info-lists .info-list:nth-child(2){
          width: 50%;
          padding-top: 0.1rem;
      }
      .info-lists .info-list:nth-child(3){
          width: 50%;
          padding-top: 0.1rem;
      }
    
  }
}
</style>
